define("discourse/plugins/discourse-calendar/discourse/models/discourse-post-event-event", ["exports", "@glimmer/tracking", "@ember/object", "@ember-compat/tracked-built-ins", "discourse/models/user", "discourse-common/utils/decorators", "discourse/plugins/discourse-calendar/discourse/models/discourse-post-event-event-stats", "discourse/plugins/discourse-calendar/discourse/models/discourse-post-event-invitee"], function (_exports, _tracking, _object, _trackedBuiltIns, _user, _decorators, _discoursePostEventEventStats, _discoursePostEventInvitee) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_REMINDER = {
    type: "notification",
    value: 15,
    unit: "minutes",
    period: "before"
  };
  class DiscoursePostEventEvent {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new DiscoursePostEventEvent(args);
    }
    static #_ = (() => dt7948.g(this.prototype, "title", [_tracking.tracked]))();
    #title = (() => (dt7948.i(this, "title"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "name", [_tracking.tracked]))();
    #name = (() => (dt7948.i(this, "name"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "categoryId", [_tracking.tracked]))();
    #categoryId = (() => (dt7948.i(this, "categoryId"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "startsAt", [_tracking.tracked]))();
    #startsAt = (() => (dt7948.i(this, "startsAt"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "endsAt", [_tracking.tracked]))();
    #endsAt = (() => (dt7948.i(this, "endsAt"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "rawInvitees", [_tracking.tracked]))();
    #rawInvitees = (() => (dt7948.i(this, "rawInvitees"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "url", [_tracking.tracked]))();
    #url = (() => (dt7948.i(this, "url"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "timezone", [_tracking.tracked]))();
    #timezone = (() => (dt7948.i(this, "timezone"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "post", [_tracking.tracked]))();
    #post = (() => (dt7948.i(this, "post"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "minimal", [_tracking.tracked]))();
    #minimal = (() => (dt7948.i(this, "minimal"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "canUpdateAttendance", [_tracking.tracked]))();
    #canUpdateAttendance = (() => (dt7948.i(this, "canUpdateAttendance"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "canActOnDiscoursePostEvent", [_tracking.tracked]))();
    #canActOnDiscoursePostEvent = (() => (dt7948.i(this, "canActOnDiscoursePostEvent"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "shouldDisplayInvitees", [_tracking.tracked]))();
    #shouldDisplayInvitees = (() => (dt7948.i(this, "shouldDisplayInvitees"), void 0))();
    static #_14 = (() => dt7948.g(this.prototype, "isClosed", [_tracking.tracked]))();
    #isClosed = (() => (dt7948.i(this, "isClosed"), void 0))();
    static #_15 = (() => dt7948.g(this.prototype, "isExpired", [_tracking.tracked]))();
    #isExpired = (() => (dt7948.i(this, "isExpired"), void 0))();
    static #_16 = (() => dt7948.g(this.prototype, "isStandalone", [_tracking.tracked]))();
    #isStandalone = (() => (dt7948.i(this, "isStandalone"), void 0))();
    static #_17 = (() => dt7948.g(this.prototype, "recurrence", [_tracking.tracked]))();
    #recurrence = (() => (dt7948.i(this, "recurrence"), void 0))();
    static #_18 = (() => dt7948.g(this.prototype, "recurrenceRule", [_tracking.tracked]))();
    #recurrenceRule = (() => (dt7948.i(this, "recurrenceRule"), void 0))();
    static #_19 = (() => dt7948.g(this.prototype, "customFields", [_tracking.tracked]))();
    #customFields = (() => (dt7948.i(this, "customFields"), void 0))();
    static #_20 = (() => dt7948.g(this.prototype, "_watchingInvitee", [_tracking.tracked]))();
    #_watchingInvitee = (() => (dt7948.i(this, "_watchingInvitee"), void 0))();
    static #_21 = (() => dt7948.g(this.prototype, "_sampleInvitees", [_tracking.tracked]))();
    #_sampleInvitees = (() => (dt7948.i(this, "_sampleInvitees"), void 0))();
    static #_22 = (() => dt7948.g(this.prototype, "_stats", [_tracking.tracked]))();
    #_stats = (() => (dt7948.i(this, "_stats"), void 0))();
    static #_23 = (() => dt7948.g(this.prototype, "_creator", [_tracking.tracked]))();
    #_creator = (() => (dt7948.i(this, "_creator"), void 0))();
    static #_24 = (() => dt7948.g(this.prototype, "_reminders", [_tracking.tracked]))();
    #_reminders = (() => (dt7948.i(this, "_reminders"), void 0))();
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.id = args.id;
      this.name = args.name;
      this.categoryId = args.category_id;
      this.upcomingDates = args.upcoming_dates;
      this.startsAt = args.starts_at;
      this.endsAt = args.ends_at;
      this.rawInvitees = args.raw_invitees;
      this.sampleInvitees = args.sample_invitees || [];
      this.url = args.url;
      this.timezone = args.timezone;
      this.status = args.status;
      this.creator = args.creator;
      this.post = args.post;
      this.isClosed = args.is_closed;
      this.isExpired = args.is_expired;
      this.isStandalone = args.is_standalone;
      this.minimal = args.minimal;
      this.recurrenceRule = args.recurrence_rule;
      this.recurrence = args.recurrence;
      this.canUpdateAttendance = args.can_update_attendance;
      this.canActOnDiscoursePostEvent = args.can_act_on_discourse_post_event;
      this.shouldDisplayInvitees = args.should_display_invitees;
      this.watchingInvitee = args.watching_invitee;
      this.stats = args.stats;
      this.reminders = args.reminders;
      this.customFields = _object.default.create(args.custom_fields || {});
    }
    get watchingInvitee() {
      return this._watchingInvitee;
    }
    set watchingInvitee(invitee) {
      this._watchingInvitee = invitee ? _discoursePostEventInvitee.default.create(invitee) : null;
    }
    get sampleInvitees() {
      return this._sampleInvitees;
    }
    set sampleInvitees(invitees) {
      if (invitees === void 0) {
        invitees = [];
      }
      this._sampleInvitees = new _trackedBuiltIns.TrackedArray(invitees.map(i => _discoursePostEventInvitee.default.create(i)));
    }
    get stats() {
      return this._stats;
    }
    set stats(stats) {
      this._stats = this.#initStatsModel(stats);
    }
    get reminders() {
      return this._reminders;
    }
    set reminders(reminders) {
      if (reminders === void 0) {
        reminders = [];
      }
      this._reminders = new _trackedBuiltIns.TrackedArray(reminders);
    }
    get creator() {
      return this._creator;
    }
    set creator(user) {
      this._creator = this.#initUserModel(user);
    }
    get isPublic() {
      return this.status === "public";
    }
    get isPrivate() {
      return this.status === "private";
    }
    updateFromEvent(event) {
      this.name = event.name;
      this.startsAt = event.startsAt;
      this.endsAt = event.endsAt;
      this.url = event.url;
      this.timezone = event.timezone;
      this.status = event.status;
      this.creator = event.creator;
      this.isClosed = event.isClosed;
      this.isExpired = event.isExpired;
      this.isStandalone = event.isStandalone;
      this.minimal = event.minimal;
      this.recurrenceRule = event.recurrenceRule;
      this.recurrence = event.recurrence;
      this.canUpdateAttendance = event.canUpdateAttendance;
      this.canActOnDiscoursePostEvent = event.canActOnDiscoursePostEvent;
      this.shouldDisplayInvitees = event.shouldDisplayInvitees;
      this.stats = event.stats;
      this.sampleInvitees = event.sampleInvitees || [];
      this.reminders = event.reminders;
    }
    removeReminder(reminder) {
      const index = this.reminders.findIndex(r => r.id === reminder.id);
      if (index > -1) {
        this.reminders.splice(index, 1);
      }
    }
    static #_25 = (() => dt7948.n(this.prototype, "removeReminder", [_decorators.bind]))();
    addReminder(reminder) {
      reminder ??= {
        ...DEFAULT_REMINDER
      };
      this.reminders.push(reminder);
    }
    static #_26 = (() => dt7948.n(this.prototype, "addReminder", [_decorators.bind]))();
    #initUserModel(user) {
      if (!user || user instanceof _user.default) {
        return user;
      }
      return _user.default.create(user);
    }
    #initStatsModel(stats) {
      if (!stats || stats instanceof _discoursePostEventEventStats.default) {
        return stats;
      }
      return _discoursePostEventEventStats.default.create(stats);
    }
  }
  _exports.default = DiscoursePostEventEvent;
});